<template>
  <network :key="$route.name" />
</template>

<script>
const Network = () => import('@/components/Network')
export default {
  components: {
    'network': Network
  }
}
</script>
